<template>
  <div class="col-lg-3">
    <div class="card my-2 mt-0 card-xxl-stretch">
      <div class="card-body p-5 pt-6 flexed">
        <div class="d-flex flex-stack mb-2">
          <h4 class="fw-bolder text-gray-800 m-0 skeleton-box"></h4>
        </div>

        <div class="d-flex">
          <span
            class="fs-2hx fw-bolder text-gray-800 me-2 skeleton-box amount"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #eee;
  border-radius: 8px;
  height: 25px;
  width: 200px;
  max-width: 100%;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  &.amount {
    margin-top: 8px;
    height: 30px;
    margin-bottom: 5px;
    width: 120px;
  }
}
</style>
