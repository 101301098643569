<template>
  <div>
    <transition name="fade">
      <div v-if="visible_modal" class="modal fade show d-block pl-0">
        <div class="modal-dialog mw-650px">
          <div class="modal-content">
            <div class="modal-header pb-0 pb-5 mb-5 justify-space-between">
              <div class="fs-3 fw-bolder">{{ title }}</div>

              <div class="btn btn-icon btn-sm btn-active-icon-primary">
                <span class="svg-icon svg-icon-2x">
                  <VueFeather @click="hide" type="x" size="18" />
                </span>
              </div>
            </div>

            <div class="modal-body pt-5 pb-5">
              <component
                v-if="element"
                v-bind:is="element"
                :record="record"
                @hide="hide"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="visible_backdrop" class="modal-backdrop"></div>
  </div>
</template>

<script>
export default {
  emits: [],

  data: () => ({
    visible_modal: false,
    visible_backdrop: false,
    record: null,
    title: null,
    element: null,
  }),

  components: {
    //
  },

  methods: {
    hide() {
      this.visible_modal = false;
      this.processing = false;

      setTimeout(() => {
        this.visible_backdrop = false;
      }, 200);
    },

    show(data) {
      // Set props.
      this.title = data.props.title;
      this.element = data.props.element;
      this.record = data.record;

      // Show modal.
      this.visible_backdrop = true;
      this.visible_modal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000000, 0.35);
}

.modal-dialog {
  z-index: 999999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.275s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .cont {
    width: calc(50% - 10px);
  }

  @media only screen and (max-width: 991px) {
    .cont {
      width: 100%;

      &:first-child {
        order: 2;
      }

      &:last-child {
        order: 1;
      }
    }
  }
}

.modal-tabs {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: -15px -3px 20px;

  li {
    flex: 2 1 auto;
    list-style: none;
    margin: 3px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 10px;
    cursor: pointer;
    background: #fff;
    transition: 0.2s;

    * {
      color: #333;
      fill: #333;
      transition: 0.2s;
    }

    &:hover {
      background: #ddf8fc;
      // border-color: #ddf8fc;
    }

    &.active {
      background: #30a6b6;
      border-color: #30a6b6;
      * {
        color: #fff;
        fill: #fff;
      }
    }

    &.error {
      background: #f06445;
      border-color: #f06445;
      * {
        color: #fff;
        fill: #fff;
      }
    }
  }

  .txt {
    display: block;
    font-weight: 500;
    line-height: 1;
  }
}
</style>
