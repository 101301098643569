<template>
  <div>
    <div class="content">
      <div class="row">
        <div class="col-lg-5">
          <div class="box-info">
            <img src="/avatar.jpg" alt="Avatar" class="mb-2" />

            <div>
              <small class="d-block">
                <b class="d-block">User ID: </b>
                {{ record.user.id }}
              </small>
            </div>
          </div>
        </div>

        <div class="col-lg-7">
          <div class="box-info store d-flex align-items-start">
            <img :src="record.store.brand.avatar || '/ph.png'" alt="Avatar" />

            <div>
              <h4 class="d-block">
                <b>{{ record.store.brand.name }}</b>
              </h4>

              <ul>
                <li>
                  {{ record.store.name }}
                </li>

                <li>
                  <span
                    class="text-capitalize badge mt-2"
                    :class="[
                      record.type === $tr.CHARGE
                        ? 'badge-success'
                        : 'badge-warning',
                    ]"
                  >
                    {{ record.type }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-12">
          <ul class="additional-details">
            <li>
              <b>Amount: </b>
              {{ $c.moneyFormat(record.amount) }}
            </li>

            <li>
              <b>Created at: </b>
              {{ $dt.getDate(record.created_at) }}
            </li>

            <li>
              <b>Transaction ID: </b>
              {{ record.id }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="pt-4 border-top px-8 pb-5 modal-actions">
      <div class="cont">
        <Button class="btn-light-dark w-100" @click="$emit('hide')">
          Close
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["hide"],

  props: {
    record: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.box-info {
  img {
    max-width: 80px;
    border-radius: 8px;
    margin-right: 25px;
  }

  ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      list-style: none;
      padding-right: 6px;
      margin-bottom: 1px;
      width: 100%;
      opacity: 0.8;

      &.locale {
        text-transform: uppercase;
      }
    }
  }

  &.store {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;

    img {
      max-width: 50px;
    }
  }
}

.additional-details {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px 0 30px;
  margin: 30px 0 0;

  li {
    list-style: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    margin: 5px 0;
    padding: 5px 0;

    &:last-child {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

@media only screen and (max-width: 991px) {
  .box-info.store {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 0 0;
    margin: 10px 0 0;
  }

  .additional-details {
    margin-top: 10px;
  }
}
</style>
