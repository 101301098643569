<template>
  <div>
    <div class="content">
      <div class="row">
        <div class="col-12 text-center">
          <p>
            <b>Note:</b> We will notify you via email once uploading finishes
            completelly. Imported file should be same as the
            <a :href="example" download> <b>example file</b> </a>, or the upload
            might fail. Also, all columns with * are <b>required</b>.
          </p>

          <template
            v-for="(input, index) in record.inputs"
            :key="'importer' + index"
          >
            <component
              v-bind:is="input.component"
              v-model="customRecord[input.prop_name]"
              :model-name="input.column"
              :type="input.type"
              :name="input.name"
              :rules="input.rules || []"
              :label="input.label"
              :ref="input.name"
              :placeholder="input.placeholder"
              :accept="input.accept || null"
              :error="input.error || null"
              :api="input.api || null"
              :form_setup="input.form_setup || null"
              :record="customRecord"
              :track_by="input.track_by || 'name'"
              class="mb-10"
              @valueChange="setValue(input.prop_name)"
            />
          </template>

          <div class="actions">
            <a :href="example" download class="btn btn-secondary"
              >Download Example</a
            >

            <Button @click="$refs.file.click()" :disabled="processing">
              <template v-if="!processing">Upload CSV File</template>
              <template v-else>
                Uploading...
                <Spinner />
              </template>
            </Button>
          </div>

          <input
            type="file"
            ref="file"
            style="display: none"
            accept=".csv,text/plain"
            @change="handleUpload"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["hide"],

  props: {
    record: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    customRecord: {},
    processing: false,
    method: null,
    example: null,
  }),

  mounted() {
    this.example =
      this.$route.name === "confirmed_bills"
        ? this.$importable.CONFIRMED_BILLS
        : this.$route.name === "stores"
        ? this.$importable.STORES
        : this.$importable.STORE_BRANDS;
    console.log(this.$route.name, this.example, this.record);
    this.method = this.record.method;

    this.record.inputs.forEach((element) => {
      this.customRecord[element.prop_name] = null;
    });
  },

  methods: {
    setValue(propName) {
      console.log(propName);
    },
    handleUpload() {
      this.processing = true;
      const formData = new FormData();
      formData.append("file", this.$refs.file.files[0]);
      for (const [key, value] of Object.entries(this.customRecord)) {
        formData.append(key, value);
      }
      this.$store
        .dispatch(this.method, formData)
        .then(() => {
          this.$toast.success(
            "Upload started! We'll notify you on email once it's done."
          );
          this.customRecord = {};

          this.$emit("hide");
        })
        .catch(() => {
          this.$toast.error("Upload failed!");
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding-bottom: 30px;

  .actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .btn.btn-secondary {
      background: rgba(0, 0, 0, 0.05);
      margin-right: 10px;

      &:hover {
        background: #c9c2b1;
      }
    }
  }
}
</style>
