<template>
  <div class="graph-wrapper" :class="{ loading: loading }">
    <Loader class="graph-loader" v-if="loading" />

    <div
      class="d-flex flex-wrap mb-5"
      :class="{ 'flex-stack': title || subtitle }"
    >
      <div class="d-flex flex-column my-2">
        <h2 v-if="title" class="text-dark mb-1 fs-2 fw-bolder">
          {{ title }}
        </h2>
        <span v-if="subtitle" class="text-gray-400 fw-bold fs-6">
          {{ subtitle }}
        </span>
      </div>

      <div class="my-2">
        <Datepicker
          class="date-picker"
          v-model="date"
          :previewFormat="previewFormat"
          :format="format"
          :enableTimePicker="false"
          @update:modelValue="$emit('dateChanged', date)"
          range
          autoApply
        />
      </div>

      <ul class="nav nav-pills nav-line-pills nav-group nav-group-outline my-2">
        <li class="nav-item me-2">
          <span
            class="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-bold"
            :class="{ active: type === 'area' }"
            @click="type = 'area'"
            >Line</span
          >
        </li>
        <li class="nav-item">
          <span
            class="nav-link btn btn-active-light btn-active-color-gray-700 btn-color-gray-400 py-2 px-5 fs-6 fw-bold"
            :class="{ active: type === 'bar' }"
            @click="type = 'bar'"
            >Bar</span
          >
        </li>
      </ul>
    </div>

    <apexchart
      width="100%"
      height="350"
      :type="type"
      :options="options"
      :series="series"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import dateHelper from "@/libs/dateTimeHelper";

export default {
  emits: ["dateChanged"],
  props: {
    setup: {
      type: Object,
      required: true,
    },

    title: {
      type: [String, null],
      required: false,
      default: null,
    },

    subtitle: {
      type: [String, null],
      required: false,
      default: null,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    type: "area",
  }),

  computed: {
    options() {
      return {
        chart: {
          id: "transaction-area-chart",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "date",
          categories: this.setup.labels.length > 0 ? this.setup.labels : [],
        },
      };
    },

    series() {
      return this.setup.series.length > 0
        ? this.setup.series
        : [
            {
              name: "",
              data: [],
            },
            {
              name: "",
              data: [],
            },
          ];
    },
  },

  setup() {
    const date = ref();

    const previewFormat = () => null;

    const format = (date) => {
      const d_start = date[0].getDate();
      const m_start = date[0].getMonth() + 1;
      const y_start = date[0].getFullYear();

      const d_end = date[1].getDate();
      const m_end = date[1].getMonth() + 1;
      const y_end = date[1].getFullYear();

      return `${y_start}-${m_start}-${d_start} / ${y_end}-${m_end}-${d_end}`;
    };

    onMounted(() => {
      date.value = dateHelper.defaultGraphRange(30);
    });

    return {
      date,
      previewFormat,
      format,
    };
  },
};
</script>

<style lang="scss" scoped>
.date-picker {
  width: 212px;
}

.graph-wrapper {
  min-height: 425px;
  position: relative;

  &.loading {
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      z-index: 1;
    }
  }

  .graph-loader {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
