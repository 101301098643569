<template>
  <div class="filters-menu" :class="{ visible: isVisible }">
    <span class="close" @click="hide">
      <VueFeather class="me-1" type="x" size="18" />
      Close
    </span>

    <component
      v-if="element"
      v-bind:is="element"
      @refetchRecords="
        (filters) =>
          $emit('refetchRecords', {
            ...filters,
            page: 1,
          })
      "
    />
  </div>
</template>

<script>
export default {
  emits: ["refetchRecords", "hide"],

  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },

    element: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-menu {
  position: fixed;
  z-index: 999999;
  top: 0;
  height: 100vh;
  background: #fff;
  padding: 30px 40px 10px;
  overflow-y: auto;
  max-height: 100vh;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0);
  right: -350px;
  width: 350px;
  transition: 0.175s linear;

  @media only screen and (max-width: 575px) {
    width: 280px;
    right: -280px;
  }

  &.visible {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
    right: 0px;
  }
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 25px;
  font-size: 12px;
  text-transform: uppercase;
  color: #666;
  font-weight: 500;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.275s;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 4px;

  &:hover {
    opacity: 1;
  }
}
</style>
