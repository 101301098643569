<template>
  <form class="form w-100" @submit.prevent="handleLogin">
    <div class="text-center mb-10">
      <h1 class="text-dark mb-3">Welcome back!</h1>
      <div class="text-gray-400 fw-bold fs-4">Log in to continue</div>
    </div>

    <div class="mb-8" v-if="errors.length > 0">
      <Alert
        v-for="(error, index) in errors"
        :key="index"
        :content="error.value[0]"
        closeable
        icon-hidden
      />
    </div>

    <div class="fv-row mb-10">
      <Input
        v-model="email"
        label="Email"
        name="email"
        type="text"
        placeholder="Write your email..."
        :rules="['required', 'email']"
      />
    </div>

    <div class="fv-row mb-10">
      <Input
        v-model="password"
        label="Password"
        name="password"
        type="password"
        placeholder="********"
        :rules="['required']"
      />
    </div>

    <div class="text-center">
      <Button type="submit" class="w-100" :disabled="processing">
        <template v-if="!processing">Log In</template>
        <template v-else>
          Hold on...
          <Spinner />
        </template>
      </Button>
    </div>
  </form>
</template>

<script>
import useValidate from "@vuelidate/core";

export default {
  data: () => ({
    v$: useValidate(),
    email: null,
    password: null,
    processing: false,
    errors: [],
  }),

  methods: {
    handleLogin() {
      this.errors = [];
      this.v$.$validate();

      if (this.v$.$error) {
        return;
      }

      this.processing = true;

      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);

      this.$auth
        .handleLogin(formData)
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          this.errors = this.$res.validationErrors(error);
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
