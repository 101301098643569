<template>
  <div class="footer py-4 d-flex flex-lg-column">
    <div
      class="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between"
    >
      <div class="text-dark order-2 order-md-1">
        <span class="text-muted fw-bold me-1">
          {{ new Date().getFullYear() }} ©
        </span>

        <router-link to="/" class="text-gray-800 text-hover-primary">
          {{ $appName }}
        </router-link>
      </div>

      <!--<ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
          <a href="#" target="_blank" class="menu-link px-2">About</a>
        </li>
        <li class="menu-item">
          <a href="#" target="_blank" class="menu-link px-2">Support</a>
        </li>
        <li class="menu-item">
          <a href="#" target="_blank" class="menu-link px-2">Purchase</a>
        </li>
      </ul>-->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
