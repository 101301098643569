<template>
  <Loader v-if="loading" />

  <div v-else class="table-wrapper">
    <div class="top-actions">
      <Input
        v-if="!searchDisabled"
        v-model="search"
        name="search"
        type="text"
        placeholder="Search records..."
      />

      <div class="right">
        <Button
          v-if="filters && hasFilters"
          class="btn-warning"
          @click="showFiltersMenu"
        >
          <VueFeather type="filter" size="18" />
          Filters
        </Button>

        <Button
          v-if="importable"
          class="btn-secondary"
          @click="showImportableModal"
        >
          <VueFeather type="file-plus" size="18" />
          Import
        </Button>

        <Button
          v-if="exportable"
          class="btn-secondary"
          @click="showExportableModal"
        >
          <VueFeather type="file-minus" size="18" />
          Export
        </Button>

        <Button
          v-if="!addNewDisabled"
          @click="() => showCreatableModal(record)"
        >
          <VueFeather type="plus" size="18" />
          {{ config.add_new_txt || "Add" }}
        </Button>
      </div>
    </div>

    <div v-if="records.length > 0" class="table-responsive">
      <div class="relative-cont">
        <div class="filter-table-loader" v-if="filter_loading">
          <div
            class="spinner-border text-primary"
            role="status"
            style="width: 3rem; height: 3rem"
          ></div>
        </div>

        <table class="table table-rounded table-striped border gy-7 gs-7">
          <thead>
            <tr
              class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
            >
              <th v-for="(column, index) in config.columns" :key="index">
                <span
                  class="sortable-column"
                  :class="{ 'text-primary': column === fetchConfig.sort }"
                  v-if="isSortableColumn(column)"
                  @click="() => sortRecords(column)"
                >
                  <div class="sortable-title">
                    {{ formatColumnName(column) }}
                  </div>

                  <!-- Sort icons -->
                  <VueFeather
                    v-if="column !== fetchConfig.sort"
                    type="minus"
                    size="12"
                  />
                  <VueFeather
                    v-else
                    :type="
                      fetchConfig.direction === 'ASC'
                        ? 'chevron-down'
                        : 'chevron-up'
                    "
                    size="12"
                  />
                </span>

                <span class="regular-column" v-else>
                  {{ formatColumnName(column) }}
                </span>
              </th>

              <th v-if="modalActions.length > 0" class="text-center">
                Actions
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(record, index) in records" :key="record.id || index">
              <td
                v-for="(column, col_index) in config.columns"
                :key="col_index"
                v-html="setColumnContent(record, column)"
                class="align-middle"
              ></td>

              <td
                class="text-center align-middle"
                v-if="modalActions.length > 0"
              >
                <Dropdown btn-class="btn-icon btn-xs btn-light-primary">
                  <template v-slot:button>
                    <VueFeather type="more-vertical" size="18" />
                  </template>

                  <template v-slot:menu-items>
                    <div
                      v-for="(action, index) in modalActions"
                      :key="index"
                      @click="
                        () =>
                          handleAction(
                            action.method,
                            record,
                            action.props || null
                          )
                      "
                      class="dropdown-item d-flex"
                      style="cursor: pointer"
                    >
                      <VueFeather :type="action.icon" size="18" />
                      <span class="d-inline-block mx-2 font-weight-semi mx-3">
                        {{ action.title }}
                      </span>
                    </div>
                  </template>
                </Dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-else class="alert alert-secondary py-5 no-records">
      <VueFeather
        class="svg-icon svg-icon-2hx svg-icon-primary me-3 my-3 mt-0"
        type="search"
        size="25"
      />

      <div class="d-flex flex-column">
        <h4 class="mb-1 text-dark">No records...</h4>
        <span>Seems like there are no records available.</span>
      </div>
    </div>

    <Pagination
      v-if="records.length > 0"
      ref="pagination"
      class="my-5"
      :meta="meta"
      @refetchRecords="refetchRecords"
    />

    <TableModal ref="modal" @refetchRecords="refetchRecords" />

    <Modal ref="detailsModal" />

    <Filters
      :is-visible="filters_visible"
      :element="filters"
      @hide="filters_visible = false"
      @refetchRecords="refetchRecords"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: true,
    filters: null,
    importable: false,
    exportable: false,
    filters_visible: false,
    filter_loading: false,
    search: null,
    awaiting_search: false,
    awaiting_deleting: false,
    unauth_exception: false,
  }),

  computed: {
    ...mapState({
      records(state, getters) {
        return getters[`${this.config.store_module}/records`];
      },

      meta(state, getters) {
        return getters[`${this.config.store_module}/meta`];
      },

      fetchConfig(state, getters) {
        return getters[`${this.config.store_module}/fetchConfig`];
      },

      hasFilters(state, getters) {
        return getters[`${this.config.store_module}/hasFilters`];
      },
    }),

    modalActions() {
      return this.config.actions !== undefined && this.config.actions.length > 0
        ? this.config.actions
        : [];
    },

    addNewDisabled() {
      if (this.config.disable_add_new !== undefined) {
        return this.config.disable_add_new;
      }

      return false;
    },

    searchDisabled() {
      if (this.config.disable_search !== undefined) {
        return this.config.disable_search;
      }

      return false;
    },
  },

  mounted() {
    this.filters = this.config.filters || null;
    this.importable = this.config.importable || false;
    this.exportable = this.config.exportable || false;
    this.fetchRecords(this.config.fetch || {});
  },

  methods: {
    fetchRecords(configs) {
      // Set up fetch configs.
      this.$store
        .dispatch(`${this.config.store_module}/setupConfig`, configs)
        .then(() => {
          this.$store
            .dispatch(`${this.config.store_module}/fetchRecords`)
            .then(() => {
              this.loading = false;
              this.filter_loading = false;

              if (this.$refs.pagination) {
                this.$refs.pagination.setPages();
              }

              // Record deleted success modal.
              if (this.awaiting_deleting && !this.unauth_exception) {
                this.$toast.success(
                  this.config.delete_success || "Record deleted."
                );

                this.awaiting_deleting = false;
              }

              if (this.unauth_exception) {
                this.$toast.error(this.unauth_exception);
                this.unauth_exception = null;
                this.awaiting_deleting = false;
              }
            });
        });
    },

    refetchRecords(configs) {
      this.filter_loading = true;

      const mergedConfigs = {
        ...this.fetchConfig,
        ...configs,
      };

      this.fetchRecords(mergedConfigs);
    },

    formatColumnName(column) {
      // Check if heading for current column exists.
      if (Object.prototype.hasOwnProperty.call(this.config.headings, column)) {
        return this.config.headings[column];
      }

      return (column.charAt(0).toUpperCase() + column.slice(1)).replace(
        /_/g,
        " "
      );
    },

    setColumnContent(record, column) {
      // Check if template for current column exists.
      if (Object.prototype.hasOwnProperty.call(this.config.templates, column)) {
        return this.config.templates[column](record);
      }

      return record[column];
    },

    isSortableColumn(column) {
      const sortable = this.config.sortable || [];
      return sortable.includes(column);
    },

    sortRecords(column) {
      // Get pre defined sortable values from store.
      const definedSort = this.fetchConfig.sort || "created_at";
      let definedDirection = this.fetchConfig.direction || "DESC";

      if (column === definedSort) {
        // If same column clicked switch thourgh ASC and DESC.
        definedDirection = definedDirection === "DESC" ? "ASC" : "DESC";
      } else {
        // If differenc column clicked set DESC as default.
        definedDirection = "DESC";
      }

      // Update main fetch config object.
      const configs = {
        ...this.fetchConfig,
        page: 1, // Set initially page 1 on each sort change.
        sort: column,
        direction: definedDirection,
      };

      this.filter_loading = true;

      // Refetch records.
      this.fetchRecords(configs);

      // Set current pagination page to 1.
      this.$refs.pagination.setCurrent(1);
    },

    handleAction(method, record, props) {
      this[method](record, props);
    },

    showDetailsPage(record, props) {
      function index(record, i) {
        if (!record) {
          return null;
        }

        return record[i];
      }
      // Getting id from dot notation reference.
      const id = props.id.split(".").reduce(index, record);

      if (id === null) {
        this.$toast.error("Record doesn't exist.");
        return;
      }

      // Pushing to page.
      this.$router.push(`${props.path}/${id}`);
    },

    showDetailsModal(record, props) {
      const data = { record, props };
      this.$refs.detailsModal.show(data);
    },

    showEditableModal(record) {
      const props = {
        action: this.$actions.EDIT,
        config: this.config,
        record,
      };

      this.$refs.modal.show(props);
    },

    showCreatableModal(record) {
      const props = {
        action: this.$actions.CREATE,
        config: this.config,
        record,
      };

      this.$refs.modal.show(props);
    },

    showDeletableModal(record) {
      const description =
        this.config.delete_msg || "Do you really wish to delete this record?";

      this.$alert
        .error({
          title: "Are you sure?",
          description,
          submit: "Yes",
          cancel: "No",
        })
        .then(() => {
          this.filter_loading = true;

          this.$store
            .dispatch(`${this.config.store_module}/deleteRecord`, record.id)
            .then(() => {
              this.awaiting_deleting = true;
              this.refetchRecords(this.fetchConfig);
            })
            .catch((error) => {
              this.unauth_exception = error;
              this.awaiting_deleting = true;
              this.refetchRecords(this.fetchConfig);
            });
        });
    },

    showFiltersMenu() {
      this.filters_visible = true;
    },

    showImportableModal() {
      const data = {
        record: {
          method: `${this.config.store_module}/import`,
          inputs:
            this.config.importerInputs != undefined
              ? this.config.importerInputs
              : [],
        },

        props: {
          element: "Importer",
          title: "Upload From CSV File",
        },
      };
      this.$refs.detailsModal.show(data);
    },

    showExportableModal() {
      this.$alert
        .info({
          title: "Export records?",
          description: "Do you wish to export these records to a CSV file?",
          submit: "Yes",
          cancel: "No",
        })
        .then(() => {
          this.$store
            .dispatch(`${this.config.store_module}/export`, "csv")
            .then(() => {
              console.log("exporting");
            });
        });
    },
  },

  watch: {
    search() {
      if (!this.awaiting_search) {
        setTimeout(() => {
          this.refetchRecords({
            ...this.fetchConfig,
            search: this.search,
            page: 1,
          });
          this.awaiting_search = false;
        }, 1000); // 1 second delay
      }
      this.awaiting_search = true;
    },
  },
};
</script>

<style lang="scss">
.table-avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &.bg-contain {
    background-size: contain;
  }

  &.default {
    opacity: 0.75;
  }
}

.relative-cont {
  position: relative;
}

.filter-table-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes loading {
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}

.sortable-column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.275s;

  &:hover {
    color: #4fc9da;
  }
}

.regular-column,
.sortable-title {
  white-space: nowrap;
}

.sortable-title {
  padding-right: 10px;
}

.top-actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 15px;

  .form-control {
    max-width: 200px;
    margin-bottom: 10px;
  }

  .right {
    margin-left: auto;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 767px) {
    .form-group,
    .form-control,
    .right,
    .btn {
      max-width: 100% !important;
      width: 100% !important;
    }

    .btn {
      order: 1;
    }

    .form-group {
      order: 2;
    }
  }
}

.no-records {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
