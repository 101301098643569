import axios from "axios";

export const interceptAxios = () => {
  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  axios.defaults.headers.common["Accept"] = "application/json";

  axios.interceptors.request.use(
    function (config) {
      config.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");

      config.headers.common["Access-Control-Allow-Origin"] =
        process.env.VUE_APP_API_URL;

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
};
