<template>
  <div class="dropdown dropstart">
    <div class="d-inline-block" v-click-outside="() => (visible = false)">
      <Button :class="btnClass" @click="visible = !visible">
        <slot name="button">Click me</slot>
      </Button>

      <div v-if="visible" class="dropdown-menu right-align show">
        <slot name="menu-items"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    btnClass: {
      type: String,
      required: false,
      default: "",
    },
  },

  data: () => ({
    visible: false,
  }),
};
</script>

<style lang="scss" scoped>
.right-align {
  top: 0;
  right: 30px;
}
</style>
