<template>
  <div class="file-uploader">
    <label v-if="label" :for="id" class="form-label fs-6 fw-bolder text-dark">
      {{ label }}
    </label>

    <ul>
      <li v-for="(file, index) in files" :key="index">
        <div class="box uploaded">
          <div v-if="file.is_image" class="image-cont">
            <img class="img-fluid" :src="file.url" :alt="file.name" />
          </div>

          <div v-else class="file">
            <VueFeather type="file-text" size="25" />
            <span>{{ file.name }}</span>
          </div>

          <div class="actions">
            <VueFeather
              @click="() => removeItem(index)"
              type="trash"
              size="20"
            />
            <VueFeather
              @click="() => previewItem(file.url)"
              type="eye"
              size="20"
            />
          </div>
        </div>
      </li>

      <!-- Uploader -->
      <li v-if="displayUploader">
        <label
          :for="id"
          class="uploader box"
          :class="{ 'is-invalid border-danger': error || v$.$error }"
        >
          <span class="cont">
            <VueFeather type="upload" size="25" />
            <b>{{ placeholder }}</b>
          </span>
        </label>
      </li>
    </ul>

    <input
      :id="id"
      type="file"
      :accept="accept"
      hidden
      @change="setFiles"
      :multiple="multiple"
    />

    <small v-if="error || v$.$error" class="text-danger my-2 mx-1 d-block">
      {{ error || v$.$errors[0].$message }}
    </small>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import * as rules from "@vuelidate/validators";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },

    modelValue: {
      type: [String, File, null],
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: "Click to upload",
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },

    accept: {
      type: String,
      required: false,
      default: "*",
    },

    error: {
      type: [String, null],
      required: false,
      default: null,
    },

    rules: {
      type: [Array, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    files: [],
    id: uuidv4(),
    v$: useValidate(),
  }),

  mounted() {
    this.initiFiles();
  },

  validations() {
    let rulesObj = {};
    if (!this.rules || this.rules.length === 0) {
      // No rules present.
      rulesObj = {};
    } else {
      // Rules are present.
      this.rules.forEach((r) => {
        rulesObj[r] = rules[r];
      });
    }

    return {
      modelValue: rulesObj,
    };
  },

  computed: {
    displayUploader() {
      if (!this.multiple) {
        return this.files.length === 0;
      }

      return true;
    },
  },

  methods: {
    initiFiles() {
      if (!this.modelValue) {
        return;
      }

      const queryIndex = this.modelValue.indexOf("?");
      let isImage = false;

      if (queryIndex >= 0) {
        const str = this.modelValue.substring(0, queryIndex);
        isImage = this.$m.isImageFromUrl(str);
      } else {
        isImage = this.$m.isImageFromUrl(this.modelValue);
      }

      this.files.unshift({
        _instance: null,
        name:
          this.modelValue.substring(this.modelValue.lastIndexOf("/") + 1) || "",
        url: this.modelValue,
        is_image: isImage,
      });
    },

    setFiles($e) {
      // Check if file was uploaded.
      if ($e.target.files.length === 0) {
        return;
      }

      this.v$.$validate();

      Array.from($e.target.files).forEach((file) => {
        this.files.unshift({
          _instance: file,
          name: file.name || "",
          url: URL.createObjectURL(file),
          is_image: this.$m.isImageType(file.type),
        });

        this.$emit("update:modelValue", file);
      });
    },

    removeItem(index) {
      this.files.splice(index, 1);
      this.$emit("update:modelValue", null);
    },

    previewItem(url) {
      window.open(url, "_blank").focus();
    },

    validate() {
      this.v$.$validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.file-uploader {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin-right: 10px;
      margin-bottom: 10px;
      display: inline-block;
      width: 100%;
      max-width: 200px;
      min-height: 150px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .box {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
    background-color: #f8f6f2;
    border-color: #f8f6f2;
    color: #716d66;
    transition: color 0.2s ease, background-color 0.2s ease;
    padding: 0.825rem 1.5rem;
    font-size: 1rem;
    border-radius: 0.95rem;
    overflow: hidden;

    &.uploader {
      cursor: pointer;

      * {
        cursor: pointer;
      }
    }

    .cont {
      display: block;
      text-align: center;

      i {
        display: table;
        margin: 0 auto 10px;
      }

      b {
        opacity: 0.67;
      }
    }

    &:hover {
      background-color: #f4f1eb;
      border-color: #f4f1eb;
      color: #716d66;
    }
  }

  .uploaded {
    padding: 0;
    position: relative;

    .image {
      background-color: #fff;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    .file {
      text-align: center;

      span {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 150px;
        margin: 0 auto;
      }
    }

    .actions {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      padding: 6px 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      * {
        position: relative;
        z-index: 3;
      }

      i {
        color: #eee;
        cursor: pointer;
        transition: 0.275s;
        margin: 0 10px;

        &:hover {
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
