import $dt from "@/libs/dateTimeHelper";

// Users table config file.
export default {
  title: "Store Brands",
  store_module: "brands",
  add_new_txt: "Request New",
  add_new_success: "Request for new brand has been sent",
  delete_msg: "Do you really wish to request deletion of this brand?",
  delete_success: "Deletion request has been sent",
  importable: true,
  exportable: true,

  fetch: {
    per_page: 5,
    sort: "created_at",
    direction: "DESC",
  },

  actions: [
    {
      title: "Edit",
      icon: "edit-2",
      method: "showEditableModal",
    },
    {
      title: "Delete",
      icon: "trash-2",
      method: "showDeletableModal",
    },
  ],

  columns: ["avatar", "name", "is_active", "active_stores_count", "created_at"],
  sortable: ["name", "is_active", "created_at"],

  headings: {
    is_active: "Active",
    avatar: "Image",
    active_stores_count: "Active stores",
  },

  templates: {
    name(record) {
      return `
        <b class="d-block">${record.name}</b>
        ${
          record.deletion_requested
            ? '<small class="text-danger">Deletion requested.</small>'
            : ""
        }
      `;
    },

    avatar(record) {
      return `
      <span
        class="table-avatar bg-contain ${!record.avatar && "default"}"
        style="background-image: url('${record.avatar || "/ph.png"}')"
      ></span>
      `;
    },

    is_active(record) {
      return `
        <span class="badge badge-light-${
          record.is_active ? "success" : "dark"
        }">
          ${record.is_active ? "Active" : "Inactive"}
        </span>
      `;
    },

    active_stores_count(record) {
      return `
        <span class="badge badge-square badge-dark">${record.active_stores_count}</span>
      `;
    },

    created_at(record) {
      return $dt.getDate(record.created_at);
    },
  },

  createForm: [
    {
      component: "Input",
      type: "regular",
      column: "name",
      name: "name",
      label: "Brand Name *",
      placeholder: "Write brand name",
      rules: ["required"],
    },
    {
      component: "SelectDropdown",
      column: "categories",
      name: "categories",
      label: "Categories *",
      placeholder: "Select related categories",
      rules: ["required"],
      api: {
        client: "categories",
        method: "list",
      },
    },
    {
      component: "Input",
      type: "regular",
      column: "address",
      name: "address",
      label: "Address *",
      placeholder: "Write brand address",
      rules: ["required"],
    },
    {
      component: "FileUpload",
      column: "avatar",
      name: "avatar",
      label: "Avatar *",
      placeholder: "Click to upload",
      accept: "image/*",
      rules: ["required"],
    },
  ],

  editForm: [
    {
      component: "Input",
      type: "regular",
      column: "name",
      name: "name",
      label: "Brand Name *",
      placeholder: "Write brand name",
      rules: ["required"],
    },
    {
      component: "SelectDropdown",
      column: "categories",
      name: "categories",
      label: "Categories *",
      placeholder: "Select related categories",
      rules: ["required"],
      api: {
        client: "categories",
        method: "list",
      },
    },
    {
      component: "Input",
      type: "regular",
      column: "address",
      name: "address",
      label: "Address *",
      placeholder: "Write brand address",
      rules: ["required"],
    },
    {
      component: "FileUpload",
      column: "avatar",
      name: "avatar",
      label: "Avatar *",
      placeholder: "Click to upload",
      accept: "image/*",
      // rules: ["required"],
    },
  ],
};
