<template>
  <div
    :ref="`alert${id}`"
    class="alert d-flex align-items-center p-5 mb-2"
    :class="[`alert-${type}`]"
  >
    <span v-if="!iconHidden" class="svg-icon svg-icon-2hx me-4">
      <VueFeather :type="icon" :class="[`text-${type}`]" />
    </span>

    <div class="d-flex flex-column">
      <h4 v-if="title" class="mb-1" :class="[`text-${type}`]">{{ title }}</h4>
      <span v-if="content">{{ content }}</span>
    </div>

    <button
      v-if="closeable"
      type="button"
      class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto"
      @click="remove"
    >
      <span class="svg-icon svg-icon-2x svg-icon-light">
        <VueFeather type="x" :class="[`text-${type}`]" />
      </span>
    </button>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },

    content: {
      type: String,
      required: false,
      default: null,
    },

    iconHidden: {
      type: Boolean,
      required: false,
      default: false,
    },

    icon: {
      type: String,
      required: false,
      default: "alert-circle",
    },

    type: {
      type: String,
      required: false,
      default: "danger",
    },

    closeable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    id: uuidv4(),
  }),

  methods: {
    remove() {
      this.$refs[`alert${this.id}`].remove();
    },
  },
};
</script>
