import $dt from "@/libs/dateTimeHelper";
import $c from "@/libs/currencyHelper";
import $conts from "@/libs/constants";

// Users table config file.
export default {
  title: "Transactions",
  store_module: "transactions",
  disable_add_new: true,
  disable_search: true,
  filters: "TransactionFilters",

  fetch: {
    per_page: 5,
    sort: "created_at",
    direction: "DESC",
  },

  actions: [
    {
      title: "Details",
      icon: "info",
      method: "showDetailsModal",
      props: {
        element: "TransactionDetails",
        title: "Transaction Details",
      },
    },
  ],

  columns: ["store", "type", "amount", "created_at"],
  sortable: ["amount", "type", "created_at"],

  headings: {
    // name: "Profile",
  },

  templates: {
    avatar(record) {
      return record.user
        ? `<span
            class="table-avatar default"
            style="background-image: url('/avatar.jpg')"
          ></span>`
        : `<span>-</span>`;
    },

    type(record) {
      return `
        <span class="text-capitalize badge badge-${
          record.type === $conts.transactionTypes.CHARGE ? "warning" : "success"
        }">
          ${record.type}
        </span>
      `;
    },

    store(record) {
      return `
      <div>
        <span>
          <b>${record.store.brand.name}</b> <br />
        </span>
        <span>${record.store.name}</span>
      </div>
      `;
    },

    amount(record) {
      return `${$c.moneyFormat(record.amount)}`;
    },

    created_at(record) {
      return $dt.getDate(record.created_at);
    },
  },

  editForm: [],
};
