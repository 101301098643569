import api from "./_req";

export default {
  update(data, cb) {
    api.post(cb, data, "/me/update");
  },

  requestDeletion(cb) {
    api.delete(cb, "/me");
  },
};
