<template>
  <div
    class="menu-item"
    :class="{
      'menu-accordion': (link.children || []).length > 0,
      show: link.collapsed || false,
    }"
  >
    <!-- Main link -->
    <a
      class="menu-link"
      :class="{ active: $route.matched.some(({ name }) => name === link.name) }"
      @click.prevent="handleButtonActions"
    >
      <span class="menu-icon">
        <VueFeather type="arrow-right" size="18" />
      </span>

      <span class="menu-title">{{ link.title }}</span>

      <span v-if="hasChildren" class="menu-arrow"></span>
    </a>

    <!-- Children list dropdown -->
    <div
      v-if="hasChildren"
      class="menu-sub menu-sub-accordion menu-active-bg"
      :class="{ show: link.collapsed || false }"
    >
      <div v-for="(inner, x) in link.children" :key="x" class="menu-item">
        <!-- Children link -->
        <router-link class="menu-link" :to="inner.path">
          <span class="menu-bullet">
            <span class="bullet bullet-dot"></span>
          </span>
          <span class="menu-title">{{ inner.title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },

    link: {
      type: Object,
      required: true,
    },
  },

  emits: ["toggleCollapse"],

  computed: {
    hasChildren() {
      return (this.link.children || []).length > 0;
    },
  },

  methods: {
    handleButtonActions() {
      if (this.hasChildren) {
        this.$emit("toggleCollapse", this.index);
        return;
      }

      // Redirect if no children exist.
      this.$router.push(this.link.path);
    },
  },
};
</script>
