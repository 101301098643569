<template>
  <div class="form-group">
    <label v-if="label" class="form-label fs-6 fw-bolder text-dark">
      {{ label }}
    </label>

    <Multiselect
      :class="{
        'is-invalid border-danger': error || v$.$error,
        'is-loading': loading,
      }"
      v-model="value"
      :options="options"
      :closeOnSelect="type != 'tags'"
      :searchable="true"
      :placeholder="placeholder"
      :mode="type"
      :trackBy="track_by"
      :label="track_by"
      valueProp="id"
      @change="v$.$validate()"
    />

    <small v-if="error || v$.$error" class="text-danger my-2 mx-1 d-block">
      {{ error || v$.$errors[0].$message }}
    </small>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import * as rules from "@vuelidate/validators";

export default {
  props: {
    modelValue: {
      type: [String, Array, Object, null],
      required: true,
    },

    modelName: {
      type: [String, null],
      required: false,
      default: null,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    placeholder: {
      type: String,
      required: false,
      default: null,
    },

    type: {
      type: String,
      required: false,
      default: "tags",
    },

    track_by: {
      type: String,
      required: false,
      default: "name",
    },

    rules: {
      type: [Array, null],
      required: false,
      default: null,
    },

    error: {
      type: [String, null],
      required: false,
      default: null,
    },

    api: {
      type: [Object, null],
      required: false,
      default: null,
    },

    optionsList: {
      type: [Array, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    v$: useValidate(),
    value: [],
    options: [],
    loading: false,
  }),

  validations() {
    let rulesObj = {};
    if (!this.rules || this.rules.length === 0) {
      // No rules present.
      rulesObj = {};
    } else {
      // Rules are present.
      this.rules.forEach((r) => {
        rulesObj[r] = rules[r];
      });
    }

    return {
      modelValue: rulesObj,
    };
  },

  mounted() {
    // Set records from api client.
    if (this.api) {
      this.fetchRecords(this.api.client, this.api.method, null).then(
        (response) => {
          this.options = response.data;
        }
      );

      // Refetch filtered values based on dependable column.
      if (this.api.depends_on) {
        this.$eventBus.on("refetchDependable", (props) =>
          this.refetchDependable({ ...props, depends_on: this.api.depends_on })
        );
      }
    }

    if (this.optionsList) {
      this.options = [...this.optionsList];
    }

    if (this.modelValue instanceof Array) {
      this.value = this.modelValue.map((value) => value.id);
      return;
    }

    if (this.modelValue instanceof Object) {
      this.value = this.modelValue.id || null;
      return;
    }

    this.value = this.modelValue;
  },

  beforeUnmount() {
    if (this.api && this.api.depends_on) {
      this.$eventBus.off("refetchDependable");
    }
  },

  methods: {
    refetchDependable(props) {
      if (props.model !== props.depends_on) {
        return;
      }

      // Reset and fetch filtered.
      this.value = this.type === "single" ? null : [];
      this.v$.$reset();

      if (this.modelValue) {
        if (this.modelValue instanceof Array) {
          this.value = this.modelValue.map((value) => value.id);
        } else if (this.modelValue instanceof Object) {
          this.value = this.modelValue.id || null;
        }
      }

      this.fetchRecords(this.api.client, this.api.method, {
        [props.depends_on]: props.value,
      }).then((response) => {
        this.options = response.data;
      });
    },

    fetchRecords(client, method, filters) {
      this.loading = true;
      const API = require(`@/api/${client}`).default;

      return new Promise((resolve, reject) => {
        API[method](filters || {}, (response) => {
          this.loading = false;

          if (response.status !== 200) {
            reject(response);
            return;
          }

          resolve(response);
        });
      });
    },

    validate() {
      this.v$.$validate();
    },
  },

  watch: {
    value() {
      this.$emit("update:modelValue", this.value);

      // Trigger dependable methods.
      this.$eventBus.emit("refetchDependable", {
        model: this.modelName,
        value: this.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.is-loading {
  opacity: 0.65;
  pointer-events: none !important;
}
</style>
