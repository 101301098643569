<template>
  <div v-if="init">
    <label
      v-if="label"
      class="form-label fs-6 fw-bolder text-dark"
      for="address"
    >
      {{ label }}
    </label>

    <GMapAutocomplete
      ref="autoComplete"
      :key="id"
      :id="id"
      class="form-control form-control-lg form-control-solid"
      :class="{ 'is-invalid border-danger': error || v$.$error }"
      :placeholder="placeholder"
      autocomplete="nope"
      :value="address"
      @blur="resetInput"
      @place_changed="setPlace"
    >
    </GMapAutocomplete>

    <small v-if="error || v$.$error" class="text-danger my-2 mx-1 d-block">
      {{ error || v$.$errors[0].$message }}
    </small>

    <!--<span class="updater" v-if="showUpdater" @click="updateMap">
      <VueFeather type="refresh-cw" size="15" />
      Update Map From Address
    </span>-->

    <GMapMap :center="center" :zoom="15" map-type-id="terrain" class="map">
      <template v-if="markers.length > 0">
        <GMapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :draggable="true"
          @dragend="updateMarker"
        />
      </template>
    </GMapMap>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import * as rules from "@vuelidate/validators";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    name: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: null,
    },

    rules: {
      type: [Array, null],
      required: false,
      default: null,
    },

    error: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    v$: useValidate(),
    id: uuidv4(),
    address: null,
    center: { lat: null, lng: null },
    markers: [],
    init: false,
    showUpdater: false,
    tempLat: null,
    tempLng: null,
  }),

  validations() {
    let rulesObj = {};
    if (!this.rules || this.rules.length === 0) {
      // No rules present.
      rulesObj = {};
    } else {
      // Rules are present.
      this.rules.forEach((r) => {
        rulesObj[r] = rules[r];
      });
    }

    return {
      address: rulesObj,
    };
  },

  mounted() {
    this.address = this.record.address || null;

    const lat = this.locationFormatter(this.record.lat || this.$map.LAT);
    const lng = this.locationFormatter(this.record.lng || this.$map.LNG);
    const lat_lng_exists =
      (this.record.lat || false) && (this.record.lng || false);

    this.setMapView(lat, lng, lat_lng_exists);
    this.init = true;
  },

  methods: {
    validate() {
      this.v$.$validate();
    },

    resetInput() {
      this.$refs.autoComplete.$forceUpdate();
    },

    triggerEmit(name, value) {
      this.$emit("valueChange", {
        name,
        value,
      });
    },

    setPlace(e) {
      if (!e.formatted_address) {
        // this.address = null;
        this.$toast.error(
          "Select the address provided in list below the input field."
        );
        return;
      }

      this.address = e.formatted_address;
      this.triggerEmit("address", this.address);

      // Show updater button.
      this.tempLat = e.geometry.location.lat();
      this.tempLng = e.geometry.location.lng();
      // this.showUpdater = true;
      // Manually update map.
      this.$nextTick(() => {
        this.updateMap();
      });
    },

    updateMap() {
      this.setMapView(this.tempLat, this.tempLng, true);
      this.tempLat = null;
      this.tempLng = null;
      this.showUpdater = false;
    },

    updateMarker(e) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();

      this.markers = [
        {
          position: { lat, lng },
        },
      ];

      this.tempLat = null;
      this.tempLng = null;
      this.showUpdater = false;
    },

    setMapView(lat, lng, showMarker) {
      // Set center position.
      this.center = { lat, lng };

      // Set marker position.
      this.markers = showMarker
        ? [
            {
              position: {
                lat,
                lng,
              },
            },
          ]
        : [];
    },

    locationFormatter(loc) {
      if (typeof loc === "string" || loc instanceof String) {
        return parseFloat(loc);
      }

      return loc;
    },
  },

  watch: {
    address: {
      handler() {
        this.validate();
      },
    },

    markers: {
      handler() {
        if (this.markers.length > 0) {
          this.triggerEmit("lat", this.markers[0].position.lat);
          this.triggerEmit("lng", this.markers[0].position.lng);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 350px;
  margin-top: 30px;
  border-radius: 12px;
  overflow: hidden;
}

.updater {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddf8fc;
  color: #30a6b6;
  margin: 15px 0 -15px;
  border-radius: 8px;
  padding: 10px;
  font-weight: 600;
  line-height: 1;
  cursor: pointer;

  * {
    color: #30a6b6;
  }

  i {
    margin-right: 5px;
  }
}
</style>
