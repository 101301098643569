<template>
  <div class="form-group">
    <label v-if="label" :for="id" class="form-label fs-6 fw-bolder text-dark">
      {{ label }}
    </label>

    <input
      class="form-control form-control-lg form-control-solid"
      :class="{
        'is-invalid border-danger': error || v$.$error,
        readonly: readonly,
      }"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :readonly="readonly"
    />

    <small v-if="error || v$.$error" class="text-danger my-2 mx-1 d-block">
      {{ error || v$.$errors[0].$message }}
    </small>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import * as rules from "@vuelidate/validators";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    modelValue: {
      type: [String, Number, null],
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    name: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: "text",
    },

    placeholder: {
      type: String,
      required: false,
      default: null,
    },

    rules: {
      type: [Array, null],
      required: false,
      default: null,
    },

    error: {
      type: [String, null],
      required: false,
      default: null,
    },

    readonly: {
      type: [Boolean, null],
      required: false,
      default: false,
    },
  },

  data: () => ({
    v$: useValidate(),
    id: uuidv4(),
  }),

  validations() {
    let rulesObj = {};
    if (!this.rules || this.rules.length === 0) {
      // No rules present.
      rulesObj = {};
    } else {
      // Rules are present.
      this.rules.forEach((r) => {
        rulesObj[r] = rules[r];
      });
    }

    return {
      modelValue: rulesObj,
    };
  },

  methods: {
    validate() {
      this.v$.$validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control.form-control-solid.readonly {
  background: transparent;
  border-color: #eee;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.3);
}
</style>
