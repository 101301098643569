<template>
  <div class="header align-items-stretch">
    <div class="header-brand">
      <router-link to="/">
        <img alt="Cupon" src="@/assets/img/logo-full.svg" class="h-25px" />
      </router-link>

      <div
        class="d-flex align-items-center d-lg-none ms-n3 me-1"
        @click="$store.dispatch('toggleSideMenu')"
      >
        <div class="btn btn-icon btn-active-color-primary w-30px h-30px">
          <VueFeather type="menu" size="25" />
        </div>
      </div>
    </div>

    <div class="toolbar">
      <div
        class="container-fluid py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between"
      >
        <div class="page-title d-flex flex-column justify-content-center me-5">
          <h1
            class="d-flex flex-column text-dark fw-bolder fs-3 mb-0 text-capitalize"
          >
            {{ $route.name ? $route.name.replace("_", " ") : "" }}
          </h1>
        </div>

        <!-- #TODO - SET UP THESE LINKS -->
        <!--<div class="d-flex align-items-center overflow-auto pt-3 pt-sm-0">
          <div class="d-flex">
            <div class="d-flex align-items-center me-4">
              <a
                href="#"
                class="btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary"
              >
                <VueFeather type="bar-chart-2" size="18" />
              </a>
            </div>

            <div class="d-flex align-items-center me-4">
              <a
                href="#"
                class="btn btn-icon btn-active-light btn-outline btn-outline-default btn-icon-gray-700 btn-active-icon-primary"
              >
                <VueFeather type="message-square" size="18" />
              </a>
            </div>

            <div class="d-flex align-items-center">
              <a href="#" class="btn btn-icon btn-primary">3</a>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
