<template>
  <div class="app-loader">
    <div class="centered">
      <div class="blob-1"></div>
      <div class="blob-2"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 999999999;
  background: #2d2424;
  overflow: hidden;
}

.centered {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blob-1,
.blob-2 {
  width: 50px;
  height: 50px;
  position: absolute;
  background: #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blob-1 {
  left: 20%;
  animation: osc-l 2s ease-in-out infinite;
}

.blob-2 {
  left: 80%;
  animation: osc-r 2s ease-in-out infinite;
  background: #b85c38;
}

@keyframes osc-l {
  0% {
    left: 20%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 20%;
  }
}

@keyframes osc-r {
  0% {
    left: 80%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 80%;
  }
}
</style>
