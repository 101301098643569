<template>
  <div class="form-group">
    <label v-if="label" :for="id" class="form-label fs-6 fw-bolder text-dark">
      {{ label }}
    </label>

    <div class="form-check form-switch form-check-custom form-check-solid">
      <input
        class="form-check-input"
        type="checkbox"
        :id="id"
        :checked="modelValue"
        @input="$emit('update:modelValue', $event.target.checked)"
      />

      <label v-if="placeholder" class="form-check-label" :for="id">
        {{ placeholder }}
      </label>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    modelValue: {
      type: [Boolean, String, null],
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    placeholder: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    id: uuidv4(),
  }),

  methods: {
    validate() {},
  },
};
</script>
