<template>
  <div v-if="loaded">
    <label v-if="label" class="form-label fs-6 fw-bolder text-dark">
      {{ label }}
    </label>

    <div class="form-group flex-form">
      <input
        v-for="(input, index) in inputs"
        :key="index"
        type="text"
        class="form-control"
        :placeholder="`Add record ${input}...`"
        v-model="form[input]"
        @keyup.enter="addRecord"
        :style="childs_style || ''"
      />

      <span class="submit-btn" @click="addRecord">Add</span>
    </div>

    <template v-if="records.length > 0">
      <draggable v-model="records" item-key="id" class="draggable-cont">
        <template #item="{ element }">
          <div class="card my-2">
            <div class="card-body p-5 cursor-pointer">
              <div class="card-text">
                <p
                  v-for="(input, index) in inputs"
                  :key="`txt_${index}`"
                  class="mb-0"
                  :style="getWidthStyleValue(inputs.length)"
                >
                  {{ element[input] }}
                </p>
              </div>

              <VueFeather
                @click="() => removeRecord(element._id)"
                class="remove"
                type="x"
                size="18"
              />
            </div>
          </div>
        </template>
      </draggable>
    </template>

    <div v-else style="margin-top: 20px">No records are available.</div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    name: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: null,
    },

    error: {
      type: [String, null],
      required: false,
      default: null,
    },

    form_setup: {
      type: [Object, null],
      required: false,
      default: null,
    },

    childs_style: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  components: {
    draggable,
  },

  data: () => ({
    value: null,

    // Initial setup.
    form: {
      name: null,
      value: null,
    },
    inputs: ["name", "value"],
    // --------------

    records: [],
    loaded: false,
  }),

  mounted() {
    this.buildFormObject();
    this.setInitialRecords();
    this.triggerEmit();
    this.loaded = true;
  },

  methods: {
    buildFormObject() {
      if (!this.form_setup) {
        return;
      }

      this.form = {};

      for (const property in this.form_setup) {
        this.form[property] = this.form_setup[property];
      }
    },

    setInitialRecords() {
      const r = this.$f.getProperty(this.name, this.record);

      const mapped = r.map((record) => {
        return {
          ...record,
          _id: uuidv4(),
        };
      });

      if (r.length > 0) {
        this.records = mapped;
      }
    },

    getWidthStyleValue(length) {
      // #TODO - Maybe make this more configurable here?
      return `max-width:${100 / length}%`;
    },

    addRecord() {
      for (const property in this.form) {
        if (!this.form[property]) {
          return;
        }
      }

      this.records.push({
        ...this.form,
        _id: uuidv4(),
      });

      for (const property in this.form) {
        this.form[property] = null;
      }
    },

    removeRecord(ID) {
      const removeIndex = this.records.findIndex((item) => item._id === ID);
      this.records.splice(removeIndex, 1);
    },

    validate() {
      return;
    },

    triggerEmit() {
      const value = this.records.map((item) => {
        delete item.id;
        return item;
      });

      this.$emit("valueChange", {
        name: this.name,
        value,
      });
    },
  },

  watch: {
    records: {
      handler() {
        this.triggerEmit();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-cont {
  margin-top: 20px;
  padding-left: 20px;
  border-left: 2px solid rgba(0, 0, 0, 0.08);
  border-radius: 1px;
}

.flex-form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .form-control {
    flex: 2 1 auto;
    margin-right: 5px;
    margin-bottom: 5px;
    width: auto;
    min-width: 250px;
  }

  .submit-btn {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background: #4fc9da;
    transition: 0.275s;
    cursor: pointer;
    color: #fff;
    display: inline-block;
    text-align: center;
    min-height: 41px;
    padding: 10px;
    font-weight: 600;

    * {
      color: #fff;
      fill: #fff;
    }

    &:hover {
      background: #30a6b6;
    }
  }
}

.card-body {
  position: relative;

  .remove {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    opacity: 0.75;
    transition: 0.275s;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid;
    text-align: center;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 1;
      color: red;
    }
  }
}

.card-text {
  display: flex;
  flex-wrap: wrap;

  p {
    flex: 2 1 auto;
    margin: 2px 5px 2px 3px;
  }
}
</style>
