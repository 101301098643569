import $dt from "@/libs/dateTimeHelper";

// Users table config file.
export default {
  title: "Stores",
  store_module: "stores",
  add_new_txt: "Request New",
  add_new_success: "Request for new brand has been sent",
  delete_msg: "Do you really wish to request deletion of this store?",
  delete_success: "Deletion request has been sent",
  importable: true,
  exportable: true,

  fetch: {
    per_page: 5,
    sort: "created_at",
    direction: "DESC",
  },

  actions: [
    {
      title: "Edit",
      icon: "edit-2",
      method: "showEditableModal",
    },
    {
      title: "Delete",
      icon: "trash-2",
      method: "showDeletableModal",
    },
  ],

  columns: ["name", "is_active", "brand", "category", "created_at"],
  sortable: ["name", "is_active", "created_at"],

  headings: {
    is_active: "Active",
    avatar: "Image",
  },

  templates: {
    name(record) {
      return `
        <b class="d-block">${record.name}</b>
        ${
          record.deletion_requested
            ? '<small class="text-danger">Deletion requested.</small>'
            : ""
        }
      `;
    },

    brand(record) {
      return `
        <div class="d-flex align-items-center">
          <img src="${record.brand.avatar || "/ph.png"}" width=20>
          <span class="mx-3">${record.brand.name}</span>
        </div>
      `;
    },

    category(record) {
      return `
        <span>${record.category.name}</span>
      `;
    },

    is_active(record) {
      return `
        <span class="badge badge-light-${
          record.is_active ? "success" : "dark"
        }">
          ${record.is_active ? "Active" : "Inactive"}
        </span>
      `;
    },

    created_at(record) {
      return $dt.getDate(record.created_at);
    },
  },

  // #TODO - Set up crete form.
  createForm: [
    {
      component: "Input",
      type: "regular",
      column: "name",
      name: "name",
      label: "Name *",
      placeholder: "Write store name",
      rules: ["required"],
      tab: "general",
    },
    {
      component: "SelectDropdown",
      type: "single",
      column: "brand",
      name: "brand",
      label: "Brand *",
      placeholder: "Select related store brand",
      rules: ["required"],
      prop_name: "store_brand_id",
      api: {
        client: "brands",
        method: "list",
      },
      tab: "general",
    },
    {
      component: "SelectDropdown",
      type: "single",
      column: "category",
      name: "category",
      label: "Category *",
      placeholder: "Select related store category",
      rules: ["required"],
      prop_name: "store_category_id",
      api: {
        client: "categories",
        method: "list",
        depends_on: "store_brand_id",
      },
      tab: "general",
    },
    {
      component: "DynamicSorter",
      name: "additional.business_hours",
      label: "Working hours",
      tab: "open_hours",
      form_setup: {
        name: null,
        value: null,
      },
    },
    {
      component: "DynamicSorter",
      name: "additional.contacts",
      label: "Contacts",
      tab: "contacts",
      form_setup: {
        name: null,
        value: null,
      },
    },
    {
      component: "Map",
      name: "address",
      placeholder: "Write store address...",
      label: "Address *",
      rules: ["required"],
      tab: "location",
    },
  ],

  editForm: [
    {
      component: "Input",
      type: "regular",
      column: "name",
      name: "name",
      label: "Name *",
      placeholder: "Write store name",
      rules: ["required"],
      tab: "general",
    },
    {
      component: "SelectDropdown",
      type: "single",
      column: "brand",
      name: "brand",
      label: "Brand *",
      placeholder: "Select related store brand",
      rules: ["required"],
      prop_name: "store_brand_id",
      api: {
        client: "brands",
        method: "list",
      },
      tab: "general",
    },
    {
      component: "SelectDropdown",
      type: "single",
      column: "category",
      name: "category",
      label: "Category *",
      placeholder: "Select related store category",
      rules: ["required"],
      prop_name: "store_category_id",
      api: {
        client: "categories",
        method: "list",
        depends_on: "store_brand_id",
      },
      tab: "general",
    },
    {
      component: "DynamicSorter",
      name: "additional.business_hours",
      label: "Working hours",
      tab: "open_hours",
      form_setup: {
        name: null,
        value: null,
      },
    },
    {
      component: "DynamicSorter",
      name: "additional.contacts",
      label: "Contacts",
      tab: "contacts",
      form_setup: {
        name: null,
        value: null,
      },
    },
    {
      component: "Map",
      name: "address",
      placeholder: "Write store address...",
      label: "Address *",
      rules: ["required"],
      tab: "location",
    },
  ],

  tabs: [
    {
      key: "general",
      name: "General",
      icon: "info",
    },
    {
      key: "location",
      name: "Location",
      icon: "map-pin",
    },
    {
      key: "contacts",
      name: "Contacts",
      icon: "phone",
    },
    {
      key: "open_hours",
      name: "Open hours",
      icon: "clock",
    },
  ],
};
