<template>
  <div v-if="loading" class="loading-cont">
    <Loader />
  </div>

  <div v-else>
    <div class="content">
      <div class="row">
        <div class="col-12">
          <div class="box-info d-flex align-items-start">
            <div class="d-block w-100">
              <div class="heading">
                <div class="dates">
                  <span>
                    Issued At:
                    <b>{{ $dt.getDateSecondary(record.created_at) }}</b>
                  </span>

                  <span>
                    Due By:
                    <b>{{ $dt.getDateSecondary(record.due_date) }}</b>
                  </span>
                </div>
                <h4>Invoice {{ record.name }}</h4>
              </div>

              <div class="transactions first-list">
                <div class="list-heading">
                  <span>#</span>
                  <span>Store</span>
                  <span>Type</span>
                  <span>Subtotal</span>
                </div>

                <div
                  v-for="(tr, index) in invoice.trans"
                  :key="tr.id"
                  class="list-items"
                >
                  <span>{{ index + 1 }}</span>
                  <span>{{ tr.store.name || "-" }}</span>
                  <span class="text-capitalize">{{ tr.type }}</span>
                  <span
                    ><b>{{ $c.moneyFormat(tr.amount) }}</b></span
                  >
                </div>
              </div>

              <div class="transactions last-list">
                <div class="list-heading">
                  <span>Payment Info</span>
                  <span>Total Due</span>
                </div>

                <div class="list-items">
                  <span>
                    Transactions Sum:
                    <b>{{ $c.moneyFormat(transactions_sum) }}</b>
                  </span>
                  <span>
                    <b>Total Due:</b>
                    {{ $c.moneyFormat(invoice.amount) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-4 border-top px-8 pb-5 modal-actions">
      <div class="cont">
        <Button class="btn-light-dark w-100" @click="$emit('hide')">
          Close
        </Button>

        <Button class="w-100" @click="openPDF">Download</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  emits: ["hide"],

  props: {
    record: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    loading: true,
  }),

  computed: {
    ...mapGetters({
      invoice: "invoices/record",
    }),

    transactions_sum() {
      if (!this.invoice || !this.invoice.trans) {
        return 0;
      }

      return this.invoice.trans.length > 0
        ? Math.round(
            this.invoice.trans
              .map((tr) => tr.amount)
              .reduce((partialSum, a) => partialSum + a, 0) * 100
          ) / 100
        : 0;
    },
  },

  mounted() {
    this.fetchRecord(this.record.id);
  },

  methods: {
    openPDF() {
      window.open(this.record.pdf_url, "_blank");
    },

    fetchRecord(id) {
      this.$store.dispatch("invoices/fetchSingle", id).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-cont {
  margin-bottom: 50px;
}

.box-info {
  img {
    max-width: 80px;
    border-radius: 8px;
    margin-right: 25px;
  }

  ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      list-style: none;
      padding-right: 6px;
      margin-bottom: 1px;
      width: 100%;
      opacity: 0.8;

      &.locale {
        text-transform: uppercase;
      }
    }
  }

  &.store {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;

    img {
      max-width: 50px;
    }
  }
}

.cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 30px;

  .dates {
    span {
      display: block;
    }

    h4 {
      text-align: right;
    }
  }
}

.transactions {
  margin: 0 0 30px;

  .list-heading,
  .list-items {
    display: flex;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 5px;

      &:nth-child(1) {
        width: 30px;
      }

      &:nth-child(2) {
        width: calc(100% - 210px);
      }

      &:nth-child(3) {
        width: 75px;
        text-align: right;
        padding: 5px 0;
      }

      &:nth-child(4) {
        width: 100px;
        text-align: right;
      }
    }
  }

  .list-heading {
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    margin: 0 0 15px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    opacity: 0.7;
  }

  .list-items {
    span {
      padding: 10px 5px;
    }
  }

  &.last-list {
    .list-heading,
    .list-items {
      span {
        width: 50%;
        padding-top: 0;

        &:last-child {
          text-align: right;
        }
      }
    }

    .list-items span:last-child {
      font-weight: 700;
      font-size: 18px;
      color: #b85c38;

      b {
        color: #333;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        opacity: 0.9;
        display: block;

        @media only screen and (min-width: 501px) {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .list-heading {
      display: none;
    }

    &.first-list {
      .list-items {
        flex-wrap: wrap;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        margin: 0 0 8px;
        padding: 0 0 8px;

        span {
          width: auto;
          width: auto;
          padding: 0 5px 2px;

          &:first-child {
            display: none;
          }

          &:nth-child(2) {
            width: 100%;
          }

          &:last-child {
            margin-left: auto;
          }
        }
      }
    }

    &.last-list {
      .list-items {
        flex-wrap: wrap;
        justify-content: flex-start;

        span {
          margin: 0 0 5px;
          width: 100%;
          text-align: left !important;
        }
      }
    }
  }
}
</style>
