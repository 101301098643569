<template>
  <div>
    <label class="form-label fs-6 fw-bolder text-dark">Transaction Types</label>

    <ul class="selector mt-2">
      <li
        v-for="(sw, index) in switches"
        :key="index"
        :class="{ active: active === sw.key }"
        @click="() => setModelValues(sw.key)"
      >
        {{ sw.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Array],
      required: true,
    },
  },

  data: () => ({
    switches: [
      { key: "cashback", name: "Cashbacks" },
      { key: "all", name: "All" },
      { key: "charge", name: "Charges" },
    ],
  }),

  computed: {
    active() {
      if (!this.modelValue || this.modelValue.length === 2) {
        return "all";
      }

      return this.modelValue[0];
    },
  },

  methods: {
    setModelValues(key) {
      if (key === "all") {
        this.$emit("update:modelValue", ["cashback", "charge"]);
        return;
      }

      this.$emit("update:modelValue", [key]);
    },
  },
};
</script>

<style lang="scss" scoped>
.selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  li {
    position: relative;
    list-style: none;
    display: block;
    width: calc(200% / 3);
    text-align: center;
    padding-top: 25px;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      top: 0px;
      width: 20px;
      height: 20px;
      border-radius: 8px;
      background: #ddd;
    }

    &::after {
      opacity: 0;
      background: #4fc9da;
      transition: 0.275s;
      width: 18px;
      height: 18px;
      margin-top: 1px;
    }

    &.active {
      color: #4fc9da;

      &::after {
        opacity: 1;
      }
    }
  }
}
</style>
