<template>
  <div class="row">
    <div class="col-12 comp">
      <TransactionTypeSwitcher v-model="filters.type" />
    </div>

    <div class="col-12 comp">
      <SliderInput
        @valueChanged="setAmountFilter"
        :fetch-min-max="true"
        label="Amount range"
      />
    </div>

    <div class="col-12 comp dropdown-selectable">
      <SelectDropdown
        class="no-carret"
        v-model="filters['store|storeBrand|id']"
        type="tags"
        column="name"
        name="name"
        label="Store Brands"
        placeholder="Select related store brand"
        prop_name="store_brand_id"
        :api="{
          client: 'brands',
          method: 'list',
        }"
      />
    </div>

    <div class="col-12 comp dropdown-selectable">
      <SelectDropdown
        class="no-carret"
        v-model="filters['store|id']"
        type="tags"
        column="name"
        name="name"
        label="Shops"
        placeholder="Select related shops"
        prop_name="store_id"
        :api="{
          client: 'stores',
          method: 'list',
        }"
      />
    </div>
  </div>
</template>

<script>
import TransactionTypeSwitcher from "../custom/TransactionTypeSwitcher";

export default {
  emits: ["refetchRecords"],

  components: {
    TransactionTypeSwitcher,
  },

  data: () => ({
    filters: {
      type: ["cashback", "charge"],
      "store|storeBrand|id": [],
      "store|id": [],
    },
  }),

  methods: {
    setAmountFilter(value) {
      this.filters.amount = value;
    },
  },

  watch: {
    filters: {
      handler() {
        this.$emit("refetchRecords", this.filters);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.comp {
  margin: 0 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border: none;
    margin: 0;
  }
}
</style>

<style lang="scss">
.dropdown-selectable {
  .multiselect-tag {
    width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 575px) {
      width: 130px;
    }
  }

  .multiselect-tag-remove {
    position: absolute;
    right: -2px;
    background: #4fc9da;
  }
}

// Slider component colors.
.slider-connect {
  background: #4fc9da;
}

.slider-tooltip {
  background: #4fc9da;
  border-color: #4fc9da;
}
</style>
