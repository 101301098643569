<template>
  <div class="card my-2 mt-0 card-xxl-stretch">
    <div class="card-body p-5 pt-6 flexed">
      <div class="mb-2">
        <h4 class="fw-bolder text-gray-800 m-0">
          {{ title }}
        </h4>

        <hr />
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style leng="scss" scoped>
hr {
  background: transparent;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}
</style>
