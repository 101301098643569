<template>
  <div
    class="aside"
    :class="{
      'aside drawer drawer-start': $deviceWidth <= 991,
      'drawer-on': asideOpened,
    }"
    v-click-outside="handleOutsideClick"
  >
    <!--<div class="aside-toolbar py-5">
      <div class="d-flex align-items-center flex-stack">
        <div class="me-3 flex-row-fluid">
          <select class="form-select form-select-white">
            <option value="1" selected="selected">Select Project</option>
            <option value="2">Good CRM App</option>
            <option value="3">Oppo Booking Site</option>
            <option value="4">FinOptima SaaS</option>
            <option value="5">Degree Mobile App</option>
          </select>
        </div>

        <a href="#" class="btn btn-icon border-0 btn-custom flex-shrink-0">
          <VueFeather type="plus" size="18" />
        </a>
      </div>
    </div>-->

    <div class="aside-menu flex-column-fluid">
      <div class="hover-scroll-overlay-y px-2 hover-menu">
        <div
          class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold"
        >
          <div v-for="(link, i) in sideMenuLinks" :key="i">
            <SideMenuLink
              :index="i"
              :link="link"
              @toggleCollapse="toggleCollapse"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="aside-footer flex-column-auto pb-5">
      <div class="aside-user">
        <div
          class="aside-user d-flex align-items-sm-center justify-content-center py-5"
        >
          <div class="me-5">
            <div class="symbol symbol-40px cursor-pointer">
              <img v-if="authUser.avatar" :src="authUser.avatar" alt="Avatar" />
              <img v-else src="@/assets/img/avatar.jpg" alt="Avatar" />
            </div>
          </div>

          <div class="flex-row-fluid flex-wrap">
            <div class="d-flex align-items-center flex-stack">
              <div class="me-2">
                <span class="text-gray-800 fs-6 fw-bold lh-0">
                  {{ authUser.first_name }} {{ authUser.last_name }}
                </span>
                <span class="text-gray-400 fw-bold d-block fs-8">
                  {{ authUser.email }}
                </span>
              </div>

              <button
                @click="handleLogout"
                class="btn btn-icon btn-active-color-primary me-n4"
                title="Log out"
              >
                <VueFeather type="log-out" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideMenuLink from "./_includes/SideMenuLink";
import { mapGetters } from "vuex";

export default {
  components: {
    SideMenuLink,
  },

  computed: {
    ...mapGetters({
      sideMenuLinks: "sideMenuLinks",
      asideOpened: "asideOpened",
      authUser: "authUser",
    }),
  },

  methods: {
    toggleCollapse(index) {
      this.$store.dispatch("toggleCollapseSideMenu", index);
    },

    handleOutsideClick() {
      if (this.asideOpened) {
        this.$store.dispatch("toggleSideMenu");
      }
    },

    handleLogout() {
      this.$auth.handleLogout().then(() => {
        this.$store.dispatch("setAuthUser", {});
        this.$router.push("/log-in");
      });
    },
  },

  watch: {
    $route() {
      this.handleOutsideClick();
    },
  },
};
</script>

<style lang="scss" scoped>
.hover-menu {
  margin-top: 20px;
  height: calc(100% - 30px);
}
</style>
