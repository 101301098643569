<template>
  <div>
    <div class="card mb-9 card-xxl-stretch">
      <div class="card-body p-5 flexed">
        <router-link
          v-for="(link, index) in links"
          :key="index"
          :to="link.path"
          class="d-flex align-items-center rounded p-5 flex-link"
          :class="[`bg-light-${link.color}`]"
        >
          <span class="svg-icon svg-icon-warning me-5">
            <span class="svg-icon svg-icon-1">
              <VueFeather
                :type="link.icon"
                :class="[`text-${link.color}`]"
                size="25"
              />
            </span>
          </span>

          <div class="flex-grow-1 me-2">
            <span class="fw-bolder fs-6 link-title">
              {{ link.name }}
            </span>

            <span class="text-muted fw-bold d-block">
              {{ link.description }}
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    links: [
      {
        path: "/brands",
        name: "Brands",
        icon: "star",
        color: "warning",
        description: "List of your brands.",
      },
      {
        path: "/stores",
        name: "Stores",
        icon: "shopping-cart",
        color: "success",
        description: "List of your stores.",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.flexed {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .flex-link {
    flex: 2 1 auto;
    margin: 5px;
  }

  .link-title {
    color: #211f1c;
    transition: 0.275s;
  }

  .flex-link:hover {
    .link-title {
      color: #4fc9da;
    }
  }
}
</style>
