import $dt from "@/libs/dateTimeHelper";
import $c from "@/libs/currencyHelper";

// Users table config file.
export default {
  title: "Invoices",
  store_module: "invoices",
  disable_add_new: true,
  disable_search: true,

  fetch: {
    per_page: 5,
    sort: "created_at",
    direction: "DESC",
  },

  actions: [
    {
      title: "Details",
      icon: "info",
      method: "showDetailsModal",
      props: {
        element: "InvoiceDetails",
        title: "Invoice Details",
      },
    },
  ],

  columns: ["name", "amount", "due_date", "created_at"],
  sortable: ["name", "amount", "due_date", "created_at"],

  headings: {
    // name: "Profile",
  },

  templates: {
    name(record) {
      return `<b>Invoice ${record.name}</b>`;
    },

    amount(record) {
      return `${$c.moneyFormat(record.amount)}`;
    },

    due_date(record) {
      return $dt.getDate(record.due_date);
    },

    created_at(record) {
      return $dt.getDate(record.created_at);
    },
  },

  editForm: [],
};
