<template>
  <div class="slider-input">
    <div class="flexed">
      <label v-if="label" class="form-label fs-6 fw-bolder text-dark">
        {{ label }}
      </label>

      <span
        class="trigger"
        :class="{ enabled: enabled }"
        @click="handleTrigger"
      >
        {{ triggerTxt }}
      </span>
    </div>

    <Loader v-if="loading" />

    <div v-else :class="{ 'slider-disabled': !enabled }">
      <Slider
        v-model="range"
        :min="min"
        :max="max"
        :tooltips="enabled"
        tooltipPosition="bottom"
        @update="handleChange"
      />
    </div>
  </div>
</template>

<script>
import Slider from "@vueform/slider";
import "@vueform/slider/themes/default.css";

export default {
  emits: ["valueChanged"],

  props: {
    label: {
      type: [String, null],
      required: false,
      default: null,
    },

    fetchMinMax: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    Slider,
  },

  data: () => ({
    range: [0, 10000],
    min: 0,
    max: 10000,
    enabled: false,
    loading: false,
  }),

  computed: {
    triggerTxt() {
      return this.enabled ? "Enabled" : "Disabled";
    },
  },

  mounted() {
    this.setMinMax();
  },

  methods: {
    setMinMax() {
      // Api route for min and mox not set.
      if (!this.fetchMinMax) {
        return;
      }

      this.loading = true;
      this.$store
        .dispatch("fetchAmountRanges", "Transaction")
        .then((amount) => {
          this.max = amount.max;
          this.min = amount.min;

          if (this.max === this.min) {
            this.min = 0;
          }

          this.loading = false;
        });
    },

    getRangeFilter() {
      return this.enabled
        ? [{ start: this.range[0] }, { end: this.range[1] }]
        : null;
    },

    handleChange() {
      this.$emit("valueChanged", this.getRangeFilter());
    },

    handleTrigger() {
      this.enabled = !this.enabled;
      this.$emit("valueChanged", this.getRangeFilter());
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-input {
  margin: 0 0 30px;
}

.slider-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.flexed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 10px;

  label {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .trigger {
    margin: 5px 0;
    cursor: pointer;
    display: inline-block;
    background: #f8f6f2;
    padding: 4px 10px;
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase;
    border-radius: 4px;
    user-select: none;
    transition: 0.275s;

    &.enabled {
      background: #ddf8fc;
      color: #4fc9da;
    }
  }
}
</style>
