<template>
  <button
    v-if="!isLink"
    :class="classNames"
    :type="type"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </button>

  <router-link v-if="to" :to="to" :class="classNames">
    <slot />
  </router-link>

  <a v-if="href" :href="href" :class="classNames">
    <slot />
  </a>
</template>

<script>
export default {
  emits: ["click"],

  props: {
    type: {
      type: String,
      required: false,
      default: "button",
    },

    to: {
      type: String,
      required: false,
      default: null,
    },

    href: {
      type: String,
      required: false,
      default: null,
    },

    class: {
      type: String,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    isLink() {
      return this.to !== null || this.href !== null;
    },
  },

  data: () => ({
    classNames: "btn btn-lg btn-primary fw-bolder me-3 my-2",
  }),

  mounted() {
    // Add class with a space before.
    this.classNames = this.classNames.concat(" " + this.class);
  },
};
</script>
