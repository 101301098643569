<template>
  <div class="col-lg-3">
    <div class="card my-2 mt-0 card-xxl-stretch">
      <div class="card-body p-5 pt-6 flexed">
        <div class="d-flex flex-stack mb-2">
          <h4 class="fw-bolder text-gray-800 m-0">{{ item.name }}</h4>
        </div>

        <div class="d-flex">
          <span class="fs-4 fw-bold text-gray-400 pt-2 me-3">
            {{ item.sign }}
          </span>

          <span
            class="fs-2hx fw-bolder text-gray-800 me-2"
            :class="{ 'cash-amount': item.sum_is_cash }"
          >
            {{ item.sum_is_cash ? $c.moneyFormat(item.sum) : item.sum }}
          </span>

          <span
            v-if="item.badge_txt"
            class="badge fs-6 p-2 d-flex align-items-center align-self-center"
            :class="[item.badge_class]"
          >
            {{ item.badge_txt }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cash-amount {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-top: 8px;
  margin-bottom: 13px;
}
</style>
