<template>
  <div class="row" v-if="form_set">
    <div class="col-lg-6">
      <div class="row">
        <div class="col-12 mb-3">
          <CardColumn title="Personal info">
            <div class="row">
              <Input
                class="mb-8 col-sm-6 col-lg-12 col-xl-6"
                v-model="form.first_name"
                label="First name *"
                placeholder="Write first name"
                name="first_name"
                :rules="['required']"
                :error="errors.first_name"
              />

              <Input
                class="mb-8 col-sm-6 col-lg-12 col-xl-6"
                v-model="form.last_name"
                label="Last name *"
                placeholder="Write last name"
                name="last_name"
                :rules="['required']"
                :error="errors.last_name"
              />

              <Input
                class="mb-8 col-sm-6 col-lg-12 col-xl-6"
                v-model="form['general_address']"
                label="Address"
                placeholder="Write your address"
                name="general_address"
                :rules="[]"
                :error="errors['general_address']"
              />

              <Input
                class="mb-8 col-sm-6 col-lg-12 col-xl-6"
                v-model="form['general_oib']"
                label="OIB *"
                placeholder="Write your OIB"
                name="general_oib"
                :rules="[]"
                :error="errors['general_oib']"
              />

              <Popper
                content="Login email change disabled! Please contact admins to request login email change."
                class="w-100"
                arrow
                hover
              >
                <Input
                  class="mb-8 col-12"
                  v-model="form.email"
                  label="Login email *"
                  placeholder="Write login email"
                  name="email"
                  :rules="['required']"
                  readonly
                />
              </Popper>
            </div>
          </CardColumn>
        </div>

        <div class="col-12 mb-3">
          <CardColumn title="Billing info">
            <Input
              class="mb-8 col-12"
              v-model="form['bank_name']"
              label="Bank name"
              placeholder="Write bank name"
              name="bank_name"
              :rules="[]"
              :error="errors['bank_name']"
            />

            <Input
              class="mb-8 col-12"
              v-model="form['bank_address']"
              label="Bank address"
              placeholder="Write bank address"
              name="bank_address"
              :rules="[]"
              :error="errors['bank_address']"
            />

            <Input
              class="mb-8 col-12"
              v-model="form['bank_iban']"
              label="IBAN"
              placeholder="Write IBAN"
              name="bank_iban"
              :rules="[]"
              :error="errors['bank_iban']"
            />

            <Input
              class="mb-8 col-12"
              v-model="form['bank_swift']"
              label="SWIFT"
              placeholder="Write SWIFT"
              name="bank_swift"
              :rules="[]"
              :error="errors['bank_swift']"
            />
          </CardColumn>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div class="col-12 mb-3">
          <CardColumn title="Contact emails">
            <Input
              class="mb-8 col-12"
              v-model="form.billing_email"
              label="Billing email"
              placeholder="Write billing email"
              name="billing_email"
              :rules="[]"
              :error="errors.billing_email"
            />

            <Input
              class="mb-8 col-12"
              v-model="form.management_email"
              label="Management email"
              placeholder="Write management email"
              name="management_email"
              :rules="[]"
              :error="errors.management_email"
            />

            <Input
              class="mb-8 col-12"
              v-model="form.support_email"
              label="Support email"
              placeholder="Write support email"
              name="support_email"
              :rules="[]"
              :error="errors.support_email"
            />
          </CardColumn>
        </div>

        <div class="col-12 mb-3">
          <CardColumn title="Contact phones">
            <DynamicSorter
              :record="form"
              name="phones"
              :form_setup="{ name: null, value: null }"
              childs_style="min-width:100px;"
              @valueChange="setValue"
            />
          </CardColumn>
        </div>

        <div class="col-12 mt-3">
          <div>
            <label class="form-label fs-6 fw-bolder text-dark">Avatar</label>
            <FileUpload
              v-model="form.avatar"
              placeholder="Click to upload"
              accept="image/*"
              name="avatar"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 my-4 mb-6">
      <Button @click="submit" :disabled="processing">
        <template v-if="!processing">Save changes</template>
        <template v-else>
          Saving...
          <Spinner />
        </template>
      </Button>

      <div>
        <span
          v-if="!user.deletion_requested"
          class="delete-btn"
          @click="showDeletableModal"
        >
          Delete account
        </span>

        <span v-else class="delete-pending text-danger">
          Deletion for your account requested.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import useValidate from "@vuelidate/core";
import CardColumn from "@/components/pages/settings/CardColumn";

import OBJ from "dot-object";
const SEPARATE = new OBJ("_");

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    CardColumn,
  },

  data: () => ({
    v$: useValidate(),
    form: null,
    errors: {},
    processing: false,
    form_set: false,
  }),

  mounted() {
    this.form = { ...this.user };
    this.formatSettingsForm(this.user.settings);

    for (const property in this.form) {
      if (property) {
        this.errors[property] = null;
      }
    }

    this.form_set = true;
  },

  methods: {
    showDeletableModal() {
      this.$alert
        .error({
          title: "Are you sure?",
          description:
            "Do you really wish to request deletion for your account?",
          submit: "Yes",
          cancel: "No",
        })
        .then(() => {
          this.$store.dispatch("requestDeletion").then(() => {
            this.$toast.success("Deletion requested");
          });
        });
    },

    setValue(props) {
      _.set(this.form, props.name, props.value);
    },

    formatSettingsForm(settings) {
      var formatted = {};

      SEPARATE.keepArray = true;
      SEPARATE.dot(settings, formatted);

      this.form = {
        ...this.form,
        ...formatted,
      };
    },

    submit() {
      for (const property in this.form) {
        if (property) {
          this.errors[property] = null;
        }
      }

      this.processing = true;
      this.v$.$validate();

      if (this.v$.$error) {
        this.$toast.error("Invalid data! Check your form.");
        this.processing = false;
        return;
      }

      // Set the form data object.
      this.form.with_settings = true;
      let formData = new FormData();
      formData = this.$f.objectToFormData(this.form);

      // Handle store action dispatching.
      this.$store
        .dispatch("updateUser", formData)
        .then(() => {
          this.$toast.success("Settings updated");
        })
        .catch((errors) => {
          this.$toast.error("Form submit failed!");

          for (const property in errors) {
            this.errors[property] = errors[property][0] || null;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  background: transparent;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.delete-pending,
.delete-btn {
  display: inline-block;
  margin: 10px 0 0 5px;
  opacity: 0.65;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}

.delete-pending {
  text-decoration: none;
  cursor: default;
  opacity: 1;
}
</style>
