<template>
  <div>
    <transition name="fade">
      <div v-if="visible_modal" class="modal fade show d-block pl-0">
        <div class="modal-dialog mw-650px">
          <div class="modal-content">
            <div class="modal-header pb-0 pb-5 mb-5 justify-space-between">
              <div class="fs-3 fw-bolder">{{ title }}</div>

              <div class="btn btn-icon btn-sm btn-active-icon-primary">
                <span class="svg-icon svg-icon-2x">
                  <VueFeather @click="hide" type="x" size="18" />
                </span>
              </div>
            </div>

            <div class="modal-body pt-5 pb-5" v-if="record">
              <ul class="modal-tabs" v-if="tabs.length > 1">
                <li
                  v-for="(tab, index) in tabs"
                  :key="index"
                  @click="active_tab = tab.key"
                  :class="{ active: active_tab === tab.key, error: tab.error }"
                >
                  <span class="icon">
                    <VueFeather :type="tab.icon" size="18" />
                  </span>
                  <span class="txt">
                    {{ tab.name }}
                  </span>
                </li>
              </ul>

              <template v-if="tabs.length > 0">
                <div
                  v-for="(tab, index) in tabs"
                  :key="`main_${index}`"
                  :class="{ 'd-none': tab.key !== active_tab }"
                  class="row"
                >
                  <div
                    v-for="(input, index) in inputs"
                    :key="index"
                    :class="input.class || 'col-12'"
                  >
                    <component
                      v-if="input.tab === tab.key"
                      v-bind:is="input.component"
                      v-model="record[input.column]"
                      :model-name="input.column"
                      :type="input.type"
                      :name="input.name"
                      :rules="input.rules || []"
                      :label="input.label"
                      :ref="input.name"
                      :placeholder="input.placeholder"
                      :accept="input.accept || null"
                      :error="input.error || null"
                      :api="input.api || null"
                      :form_setup="input.form_setup || null"
                      :record="record"
                      :track_by="input.track_by || 'name'"
                      :options-list="input.options || null"
                      class="mb-10"
                      @valueChange="setValue"
                    />
                  </div>
                </div>
              </template>
            </div>

            <div class="pt-4 border-top px-8 pb-5 modal-actions">
              <div class="cont">
                <Button
                  class="btn-light-dark w-100"
                  @click="hide"
                  :disabled="processing"
                >
                  Cancel
                </Button>
              </div>

              <div class="cont">
                <Button class="w-100" @click="submit" :disabled="processing">
                  <template v-if="!processing">Submit</template>
                  <template v-else>
                    Saving...
                    <Spinner />
                  </template>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="visible_backdrop" class="modal-backdrop"></div>
  </div>
</template>

<script>
import _ from "lodash";
import useValidate from "@vuelidate/core";

export default {
  emits: ["refetchRecords"],

  data: () => ({
    v$: useValidate(),
    visible_modal: false,
    visible_backdrop: false,
    processing: false,

    record: null,

    inputs: [],
    tabs: [],
    active_tab: null,
    action: null,
    title: "Create record",
    create_success: null,
    store_module: null,
  }),

  components: {
    // Input: () => import("./Input.vue"),
  },

  methods: {
    setValue(props) {
      _.set(this.record, props.name, props.value);
    },

    hide() {
      this.visible_modal = false;
      this.processing = false;

      setTimeout(() => {
        this.visible_backdrop = false;
      }, 200);
    },

    show(props) {
      this.setProps(props);
      this.visible_backdrop = true;
      this.visible_modal = true;
    },

    setProps(props) {
      this.action = props.action;
      this.store_module = props.config.store_module;
      this.record = { ...props.record };

      // Keep as default some general tab.
      this.tabs = props.config.tabs || [{ key: "general", name: "General" }];

      // Add error prop to tabs.
      this.tabs.map((tab) => (tab.error = false));

      this.active_tab = "general";

      if (props.action === this.$actions.EDIT) {
        this.title = "Edit record";
        this.inputs = this.setInputs(props.config.editForm);
      }

      if (props.action === this.$actions.CREATE) {
        this.title = "Create record";
        this.create_success = props.config.add_new_success || "Record created";
        this.inputs = this.setInputs(props.config.createForm);
      }

      // Empty object, for Add new modal.
      if (Object.keys(this.record).length === 0) {
        this.record = this.setRecordFromInputs(this.inputs);
      }

      const inputsWithPropName = this.inputs.filter(
        (input) => input.prop_name != undefined
      );

      inputsWithPropName.forEach((input) => {
        if (input.prop_name !== input.column) {
          // Set custom prop column on record object.
          this.record[input.prop_name] = this.record[input.column];
          delete this.record[input.column];

          // Set custom prop column value.
          input.column = input.prop_name;
          input.name = input.prop_name;
        }
      });
    },

    setInputs(inputs) {
      if (inputs.length === 0) {
        return;
      }

      return inputs.map((input) => ({
        ...input,
        error: null,
        tab: input.tab != undefined ? input.tab : "general",
      }));
    },

    setRecordFromInputs(inputs) {
      if (inputs.length === 0) {
        return {};
      }

      let r = {};

      inputs.forEach((input) => {
        r[input.column] = null;
      });

      return r;
    },

    submit() {
      this.processing = true;
      this.inputs = this.inputs.map((input) => ({
        ...input,
        error: null,
      }));

      let tabErrors = [];

      this.v$.$validate();
      this.inputs.forEach((input) => {
        this.$refs[input.name].validate();

        // Set tab errors.
        if (
          this.$refs[input.name].v$ !== undefined &&
          this.$refs[input.name].v$.$error
        ) {
          if (!tabErrors.includes(input.tab)) {
            tabErrors.push(input.tab);
          }
        }
      });

      this.tabs.forEach((tab) => {
        tab.error = tabErrors.includes(tab.key);
      });

      if (this.v$.$error) {
        this.$toast.error("Invalid data! Check your form.");
        this.processing = false;
        return;
      }

      // Set the form data object.
      let formData = new FormData();
      formData = this.$f.objectToFormData(this.record);

      const storeAction =
        this.action === this.$actions.EDIT ? "updateRecord" : "createRecord";

      const toastMessage =
        this.action === this.$actions.EDIT
          ? "Record updated."
          : this.create_success;

      // Handle store action dispatching.
      this.$store
        .dispatch(`${this.store_module}/${storeAction}`, formData)
        .then(() => {
          this.$emit("refetchRecords", {});
          this.hide();
          this.$toast.success(toastMessage);
        })
        .catch((errors) => {
          this.$toast.error("Form submit failed!");
          // Set error message for each input.
          for (const property in errors) {
            this.inputs.find((input) => input.column === property).error =
              errors[property][0];
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000000, 0.35);
}

.modal-dialog {
  z-index: 999999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.275s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .cont {
    width: calc(50% - 10px);
  }

  @media only screen and (max-width: 991px) {
    .cont {
      width: 100%;

      &:first-child {
        order: 2;
      }

      &:last-child {
        order: 1;
      }
    }
  }
}

.modal-tabs {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: -15px -3px 20px;

  li {
    flex: 2 1 auto;
    list-style: none;
    margin: 3px;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 10px;
    cursor: pointer;
    background: #fff;
    transition: 0.2s;

    * {
      color: #333;
      fill: #333;
      transition: 0.2s;
    }

    &:hover {
      background: #ddf8fc;
      // border-color: #ddf8fc;
    }

    &.active {
      background: #30a6b6;
      border-color: #30a6b6;
      * {
        color: #fff;
        fill: #fff;
      }
    }

    &.error {
      background: #f06445;
      border-color: #f06445;
      * {
        color: #fff;
        fill: #fff;
      }
    }
  }

  .txt {
    display: block;
    font-weight: 500;
    line-height: 1;
  }
}
</style>
