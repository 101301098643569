<template>
  <div>
    <div class="row">
      <!-- Skeleton loaders -->
      <template v-if="loading">
        <CounterSkeletonLoader />
        <CounterSkeletonLoader />
        <CounterSkeletonLoader />
        <CounterSkeletonLoader />
      </template>

      <template v-else>
        <Counter :item="counters[$t.STORE_BRAND]" />
        <Counter :item="counters[$t.STORE]" />
        <Counter :item="counters[$t.TRANSACTION_CB]" />
        <Counter :item="counters[$t.TRANSACTION_CH]" />
      </template>
    </div>
  </div>
</template>

<script>
import CounterSkeletonLoader from "./CounterSkeletonLoader";
import Counter from "./Counter";

export default {
  components: {
    CounterSkeletonLoader,
    Counter,
  },

  data: () => ({
    counters: {},
    counts: 0,
    loadable: [],
    loading: true,
  }),

  mounted() {
    this.loadable = [
      this.$t.STORE_BRAND,
      this.$t.STORE,
      this.$t.TRANSACTION_CB,
      this.$t.TRANSACTION_CH,
    ];

    this.loadable.forEach((record) => {
      this.counters[record] = null;
      this.fetchCounts(record);
    });
  },

  methods: {
    fetchCounts(type) {
      this.$store.dispatch("dashboard/fetchWidgetCounts", type).then((data) => {
        this.counters[type] = data;
        this.counts = this.counts + 1;
        this.loading = this.counts !== this.loadable.length;
      });
    },
  },

  unmounted() {
    this.counters = {};
  },
};
</script>
