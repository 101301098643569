import $dt from "@/libs/dateTimeHelper";

// Users table config file.
export default {
  title: "Confirmed Bills",
  store_module: "confirmed_bills",
  add_new_txt: "Add New",
  add_new_success: "New Confirmed bill added",
  delete_msg: "Do you really wish to delete this confirmed bill?",
  delete_success: "Deleted successfully",
  importable: true,
  exportable: true,

  fetch: {
    per_page: 5,
    sort: "created_at",
    direction: "DESC",
  },

  actions: [
    {
      title: "Edit",
      icon: "edit-2",
      method: "showEditableModal",
    },
    {
      title: "Delete",
      icon: "trash-2",
      method: "showDeletableModal",
    },
  ],

  columns: ["store", "zki", "jir", "amount", "status", "created_at"],

  headings: {
    // name: "Profile",
  },

  templates: {
    created_at(record) {
      return $dt.getDate(record.created_at);
    },
    store(record) {
      return record.store ? record.store.name : `<i>-</i>`;
    },
    status(record) {
      return record.status.toUpperCase();
    },
  },

  createForm: [
    {
      component: "Input",
      type: "regular",
      column: "zki",
      name: "zki",
      label: "ZKI",
      placeholder: "zki",
      rules: [],
    },
    {
      component: "Input",
      type: "regular",
      column: "jir",
      name: "jir",
      label: "JIR",
      placeholder: "jir",
      rules: [],
    },
    {
      component: "Input",
      type: "number",
      column: "amount",
      name: "amount",
      label: "Amount *",
      placeholder: "Amount",
      rules: ["required"],
    },
    {
      component: "SelectDropdown",
      type: "single",
      column: "store",
      name: "store",
      label: "Store *",
      placeholder: "Select related store",
      rules: ["required"],
      prop_name: "store_id",
      api: {
        client: "stores",
        method: "list",
      },
      tab: "general",
    },
    {
      component: "SelectDropdown",
      type: "single",
      column: "status",
      name: "status",
      label: "Status *",
      placeholder: "Select status",
      rules: ["required"],
      options: [
        { id: "approved", name: "Approved" },
        { id: "rejected", name: "Rejected" },
      ],
      tab: "general",
    },
  ],

  editForm: [
    {
      component: "Input",
      type: "regular",
      column: "zki",
      name: "zki",
      label: "ZKI",
      placeholder: "zki",
      rules: [],
    },
    {
      component: "Input",
      type: "regular",
      column: "jir",
      name: "jir",
      label: "JIR",
      placeholder: "jir",
      rules: [],
    },
    {
      component: "Input",
      type: "number",
      column: "amount",
      name: "amount",
      label: "Amount *",
      placeholder: "Amount",
      rules: ["required"],
    },
    {
      component: "SelectDropdown",
      type: "single",
      column: "store",
      name: "store",
      label: "Store *",
      placeholder: "Select related store",
      rules: ["required"],
      prop_name: "store_id",
      api: {
        client: "stores",
        method: "list",
      },
      tab: "general",
    },
    {
      component: "SelectDropdown",
      type: "single",
      column: "status",
      name: "status",
      label: "Status *",
      placeholder: "Select status",
      rules: ["required"],
      prop_name: "status",
      options: ["approved", "rejected"],
      tab: "general",
    },
  ],

  importerInputs: [
    {
      component: "SelectDropdown",
      type: "single",
      column: "store",
      name: "store",
      label: "Select Store",
      placeholder: "Select related store",
      rules: ["required"],
      prop_name: "store_id",
      api: {
        client: "stores",
        method: "list",
      },
      tab: "general",
    },
  ],
};
