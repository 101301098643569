import store_brands from "./tables/store_brands";
import stores from "./tables/stores";
import transactions from "./tables/transactions";
import invoices from "./tables/invoices";
import confirmed_bills from "./tables/confirmed_bills";

export default {
  store_brands,
  stores,
  transactions,
  invoices,
  confirmed_bills,
};
