<template>
  <ul v-if="pages.length > 1" class="pagination">
    <li
      @click="() => arrowChangePage(-1)"
      class="page-item previous"
      :class="{ disabled: current <= 1 }"
    >
      <span class="page-link"><i class="previous"></i></span>
    </li>

    <li
      class="page-item"
      v-for="(page, index) in pages"
      :key="index"
      :class="{ active: page.active, is_more: page.is_more }"
      @click="() => changePage(page)"
    >
      <span class="page-link">{{ page.count }}</span>
    </li>

    <li
      class="page-item next"
      :class="{ disabled: current >= meta.last_page }"
      @click="() => arrowChangePage(1)"
    >
      <a href="#" class="page-link"><i class="next"></i></a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    meta: {
      type: Object,
      required: true,
    },
  },

  computed: {
    showPrev() {
      return this.current;
    },
  },

  data: () => ({
    pages: [],
    current: 1,
    last: 100,
    limit: 3,
  }),

  mounted() {
    this.setPages();
  },

  methods: {
    setPages() {
      this.current = this.meta.current_page;
      this.last = this.meta.last_page;

      var range = [];
      var rangeWithDots = [];
      var l = 0;

      const left = this.current - this.limit;
      const right = this.current + this.limit + 1;

      for (let i = 1; i <= this.last; i++) {
        if (i == 1 || i == this.last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push({
              is_more: false,
              active: this.meta.current_page === l + 1,
              count: l + 1,
            });
          } else if (i - l !== 1) {
            rangeWithDots.push({
              is_more: true,
              active: false,
              count: "...",
            });
          }
        }

        rangeWithDots.push({
          is_more: false,
          active: this.meta.current_page === i,
          count: i,
        });
        l = i;
      }

      this.pages = rangeWithDots;
    },

    changePage(triggered) {
      this.pages.map((page) => {
        page.active = page.count === triggered.count;

        if (page.count === triggered.count) {
          this.current = triggered.count;
        }
      });

      this.$emit("refetchRecords", { page: triggered.count });
    },

    arrowChangePage(operator) {
      if (
        (operator < 0 && this.current === 1) ||
        (operator > 0 && this.current === this.meta.last_page)
      ) {
        return;
      }

      const count = this.current + operator;
      const triggered = this.pages.find((page) => page.count === count);
      this.changePage(triggered);
    },

    setCurrent(count) {
      this.current = count;
      this.pages.forEach((page, index) => {
        page.active = index === count - 1;
      });
    },
  },
};
</script>

<style scoped>
.page-item {
  cursor: pointer;
}
</style>
